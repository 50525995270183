import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SecondPage = () => (
  <Layout>
    <SEO title="Curiosidades" />
    <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "IMG_3551.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1140) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ width: "100%", marginTop: -38 }}
            />
          )}
        />
    
    <p>Podem contribuir ajudando na festa através do <br/>NIB: PT50001901170020001590748</p>
  </Layout>
)

export default SecondPage
